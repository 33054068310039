import React from 'react';
import banner from "./img/banner.png";
import { BsArrowRight } from 'react-icons/bs';

function Banner() {
  return (
    <div className='banner'>
      <div className="hero">
        <div className="hero-text">
          {/* Top Section */}
          <h1>
            <strong>Health Hacks for Busy Professionals</strong>
          </h1>

          {/* Next Section */}
          <p>
          Simple Exercises & Nutrition Tips for Your Hectic Lifestyle
          </p>
          
          <a href="https://zfrmz.in/Iez4zNfokUKhs59tqK62">
            <button>Enroll today <BsArrowRight /></button>
          </a>
        </div>

        <div className="hero-img">
          <img src={banner} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Banner;
