import m1 from "./img/m1.png"
import m2 from "./img/m2.png"
import m3 from "./img/m3.png"
import m4 from "./img/m4.png"
import m5 from "./img/m5.png"
export const Data=[
{
img: m4,
heading:"Tailored for Professionals" ,
text1: "Solutions that fit into your demanding lifestyle.",

},
{
img: m5,
heading:"Tech-Driven Results" ,
text1: "Leverage AI and deep tech for precise assessments and better outcomes.",

},
{
img: m3,
heading:"Expert Guidance" ,
text1: "Guidance from expert and highly experienced coaches.",

},
]